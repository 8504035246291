import React, { useState } from 'react';
import '../Authentication.scss';
import logo from '../../../assets/images/logo.svg';
import { LockOutlined } from '@ant-design/icons';
import { Button, Input, Typography, Divider, notification } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FacebookAuth } from '../SocialLogin/FacebookAuth';
import { GoogleAuth } from '../SocialLogin/GoogleAuth';
import { forgotpasswordconfirm } from '../../../api/api';
const { Title, Text } = Typography;

export const ForgotPasswordConfirm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [buttonLoading, setbuttonLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: async (values) => {
      setbuttonLoading(true);
      // Handle form submission
      const forgotPasswordConfirmResponse = await forgotpasswordconfirm({
        ...values,
        token: params.token,
      });

      if (forgotPasswordConfirmResponse.results) {
        notification.success({
          message: 'Your password has successfully been changed.',
        });
        navigate('/login');
      }
      setbuttonLoading(false);
    },
  });

  return (
    <div className='auth-wrapper'>
      <div className='left-container'>
        <img className='logo' src={logo} alt='Logo' />
      </div>
      <div className='right-container'>
        <img className='logo' src={logo} alt='Logo' />
        <div style={{ marginBottom: '32px', textAlign: 'center' }}>
          <Title level={2} style={{ marginBottom: '5px' }}>
            Welcome back
          </Title>
          <Text>Input your new password</Text>
        </div>
        <div style={{ marginBottom: '28px' }}>
          <Input.Password
            size='large'
            type='password'
            placeholder='Password'
            prefix={<LockOutlined style={{ color: '#A558C8' }} />}
            id='password'
            name='password'
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            visibilityToggle={{
              visible: passwordVisible,
              onVisibleChange: setPasswordVisible,
            }}
          />

          {formik.touched.password && formik.errors.password && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.password}
            </div>
          )}
        </div>

        <Button
          loading={buttonLoading}
          type='primary'
          onClick={() => formik.handleSubmit()}
          disabled={!formik.isValid}
        >
          Submit
        </Button>
        <Divider>or login with</Divider>

        <FacebookAuth />
        <GoogleAuth />

        <div style={{ color: '#ACACAC', textAlign: 'center' }}>
          Don't have an account?{' '}
          <Link
            to='/register'
            style={{ textDecoration: 'none', color: '#5D5D5D' }}
          >
            Sign Up
          </Link>
        </div>
      </div>
    </div>
  );
};
