import React, { useEffect, useState } from 'react';
import { Image, Button, Spin, Empty } from 'antd';
import {
  EnvironmentOutlined,
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from '@ant-design/icons';
import CountUp from 'react-countup';
import { constants } from '../../utils/constants';

import './PublicProfile.scss';
import {
  createOrFindChannel,
  followUser,
  getSuggestedUsers,
  getUser,
  subscribeToCreator,
  unFollowUser,
} from '../../api/api';
import { useNavigate, useParams } from 'react-router-dom';
import { PostContent } from '../../components/PostContent/PostContent';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { suggestions } from '../../store/suggestions';
import { appHeader } from '../../store/header';
import { Breakpoint } from 'react-socks';
import { profilePosts } from '../../store/profile';

export const PublicProfile = () => {
  const setHeader = useSetRecoilState(appHeader);
  const params = useParams();
  const navigate = useNavigate();
  const setPosts = useSetRecoilState(profilePosts);
  const posts = useRecoilValue(profilePosts);

  const setSuggestedUsers = useSetRecoilState(suggestions);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    getUserProfile();

    return () => setPosts([]);
  }, [params]);

  const getSuggestedProfiles = async () => {
    const suggestedProfiles = await getSuggestedUsers();
    if (suggestedProfiles?.results) {
      setSuggestedUsers(suggestedProfiles?.results);
    }
  };

  const getUserProfile = async () => {
    const userProfile = await getUser(params.userId);

    if (userProfile?.results) {
      setUserData(userProfile?.results);
      setPosts(userProfile?.results?.posts);
      setHeader({
        name: `${userProfile?.results.firstname} ${userProfile?.results.lastname}`,
      });
    }
    setLoading(false);
  };

  const followProfile = async () => {
    const follow = await followUser(params.userId);

    if (follow?.results) {
      getSuggestedProfiles();
      getUserProfile();
    }
  };

  const unfollowProfile = async () => {
    const unfollow = await unFollowUser(params.userId);

    if (unfollow?.results) {
      getSuggestedProfiles();
      getUserProfile();
    }
  };

  const createOrFindChatChannel = async () => {
    const chatChannel = await createOrFindChannel(userData.id);

    if (chatChannel?.results) {
      setHeader({
        name: `${userData.firstname} ${userData.lastname}`,
        picture: `${constants.mediaURL}${userData.picture}`,
      });
      navigate(`/message/${userData.id}/${chatChannel?.results?.id}`);
    }
  };

  const subscribe = async () => {
    const subscription = await subscribeToCreator(userData.id);

    if (subscription?.results) {
      getUserProfile();
    }
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Spin size='large' />
      </div>
    );
  }

  if (userData.usertype === 'USER') {
    return <Empty description='This user is not a creator.' />;
  }

  return (
    <div className='profile'>
      <div>
        <Image
          className='profile-cover-image'
          width={'100%'}
          src={`${constants.mediaURL}${userData.cover}`}
          fallback={require('../../assets/images/profile-wall.png')}
        />
      </div>

      <div className='profile-info'>
        <Image
          width={120}
          height={120}
          style={{ borderRadius: '15px', objectFit: 'cover' }}
          src={`${constants.mediaURL}${userData.picture}`}
          fallback='https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
        />

        <div className='profile-info-user'>
          <div>
            <h3
              style={{ color: '#A069E2' }}
            >{`${userData.firstname} ${userData.lastname}`}</h3>
            {userData.location && (
              <h3>
                <EnvironmentOutlined /> {userData.location}
              </h3>
            )}
          </div>

          <Breakpoint customQuery='(min-width: 768px)' className='left-panel'>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div>
                <h3 style={{ color: '#A069E2' }}>Fans</h3>
                <h3>
                  <CountUp end={userData._count.followers} duration={2} />
                </h3>
              </div>
              <div style={{ marginLeft: '15px' }}>
                <h3 style={{ color: '#A069E2' }}>Total posts</h3>
                <h3>
                  <CountUp end={posts.length} duration={2} />
                </h3>
              </div>
            </div>
          </Breakpoint>
        </div>
      </div>

      <Breakpoint customQuery='(max-width: 768px)' className='left-panel'>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div style={{ textAlign: 'center' }}>
            <h3 style={{ color: '#A069E2' }}>Fans</h3>
            <h3>
              <CountUp end={userData._count.followers} duration={2} />
            </h3>
          </div>
          <div style={{ marginLeft: '15px', textAlign: 'center' }}>
            <h3 style={{ color: '#A069E2' }}>Total posts</h3>
            <h3>
              <CountUp end={posts.length} duration={2} />
            </h3>
          </div>
        </div>
      </Breakpoint>

      <div
        style={{
          marginTop: '15px',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 10px',
        }}
      >
        <div style={{ display: 'flex' }}>
          {userData.instagram && (
            <a href={userData.instagram} target='_blank' rel='noreferrer'>
              <Button
                style={{ marginRight: '10px' }}
                type='primary'
                icon={<InstagramOutlined />}
              />
            </a>
          )}
          {userData.twitter && (
            <a href={userData.twitter} target='_blank' rel='noreferrer'>
              <Button
                style={{ marginRight: '10px' }}
                type='primary'
                icon={<TwitterOutlined />}
              />
            </a>
          )}
          {userData.facebook && (
            <a href={userData.facebook} target='_blank' rel='noreferrer'>
              <Button type='primary' icon={<FacebookOutlined />} />
            </a>
          )}
        </div>

        <div>
          <Button
            style={{ marginRight: '20px' }}
            onClick={() => createOrFindChatChannel()}
            type='primary'
          >
            Slide into DM
          </Button>
          {userData.isFollowing ? (
            <Button onClick={() => unfollowProfile()} type='primary'>
              Unfollow
            </Button>
          ) : (
            <Button onClick={() => followProfile()} type='primary'>
              Follow
            </Button>
          )}
        </div>
      </div>

      {/* <div
        style={{
          margin: '10px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={() => subscribe()} type='primary'>
          Subscribe now for $
        </Button>
      </div> */}

      <div className='profile-bio'>{userData.bio}</div>

      <PostContent posts={posts} user={userData} />
    </div>
  );
};
