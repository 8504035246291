import React from 'react';
import { Space, Menu, Dropdown } from 'antd';
import Sider from 'antd/es/layout/Sider';
import {
  UserOutlined,
  HeartFilled,
  LogoutOutlined,
  HomeOutlined,
  BellOutlined,
  MessageOutlined,
  PlusCircleFilled,
} from '@ant-design/icons';

import './LeftPanel.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isUserLoggedIn, user } from '../../store/user';
import { userType } from '../../utils/constants';
import ThemeSwitch from '../../components/ThemeSwitch/ThemeSwitch';

const LeftPanel = () => {
  const setUser = useSetRecoilState(user);
  const isLoggedIn = useSetRecoilState(isUserLoggedIn);
  const location = useLocation();
  const navigate = useNavigate();
  const userData = useRecoilValue(user);

  const items = [
    {
      label: 'New post',
      key: '1',
      onClick: (e) => navigate('/new-post'),
    },
    {
      label: 'New story',
      key: '2',
      disabled: true,
    },
  ];

  const routes =
    userData.usertype === userType.user
      ? [
          {
            key: '/feed',
            icon: <HomeOutlined />,
            label: <Link to='feed'>Feed</Link>,
          },
          {
            key: '/notifications',
            icon: <BellOutlined />,
            label: <Link to='notifications'>Notifications</Link>,
          },
          {
            key: '/messages',
            icon: <MessageOutlined />,
            label: <Link to='/messages'>Messages</Link>,
          },
          {
            key: '/creators',
            icon: <HeartFilled />,
            label: (
              <Link to='creators' className='special-link'>
                Creators
              </Link>
            ),
          },

          {
            key: '7',
            icon: <LogoutOutlined />,
            label: 'Logout',
            onClick: () => {
              setUser(null);
              isLoggedIn(false);
              localStorage.removeItem('user');
              navigate('/');
            },
          },
        ]
      : [
          {
            key: '/feed',
            icon: <HomeOutlined />,
            label: <Link to='feed'>Feed</Link>,
          },
          {
            key: '2',
            icon: <BellOutlined />,
            label: <Link to='notifications'>Notifications</Link>,
          },
          {
            key: '/messages',
            icon: <MessageOutlined />,
            label: <Link to='/messages'>Messages</Link>,
          },
          {
            key: '/profile',
            icon: <UserOutlined />,
            label: <Link to='/profile'>My Profile</Link>,
          },
          {
            key: '/fans',
            icon: <HeartFilled />,
            label: (
              <Link to='fans' className='special-link'>
                Fans
              </Link>
            ),
          },

          {
            key: '7',
            icon: <LogoutOutlined />,
            label: 'Logout',
            onClick: () => {
              setUser(null);
              isLoggedIn(false);
              localStorage.removeItem('user');
              navigate('/');
            },
          },
        ];

  return (
    <Sider
      className='left-panel-wrapper'
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <div className='navigation'>
        <div className='logo'></div>
        <Menu
          theme='light'
          mode='inline'
          defaultSelectedKeys={[location.pathname]}
          items={routes}
        />
      </div>

      <div className='theming'>
        {userData.usertype !== userType.user && (
          <div className='user-snippet'>
            <Dropdown
              className='create-drop'
              menu={{
                items,
              }}
            >
              <Space>
                <PlusCircleFilled style={{ color: 'white' }} />
                <span>Create</span>
              </Space>
            </Dropdown>
          </div>
        )}

        <ThemeSwitch />
      </div>
    </Sider>
  );
};

export default LeftPanel;
