import React, { useEffect, useState } from 'react';
import { Avatar, List, Spin } from 'antd';
import { getAllChannels } from '../../api/api';
import { constants } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { appHeader } from '../../store/header';
import './Chatlist.scss';

export const ChatList = () => {
  const setHeader = useSetRecoilState(appHeader);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getChannlesList();
    setHeader({ name: 'Messages' });
  }, []);

  const getChannlesList = async () => {
    const list = await getAllChannels();

    if (list?.results) {
      console.log(list?.results);
      setList(list?.results);
    }

    setLoading(false);
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Spin size='large' />
      </div>
    );
  }

  return (
    <List
      itemLayout='horizontal'
      dataSource={list}
      renderItem={(item) => (
        <List.Item
          onClick={() => {
            setHeader({
              name: `${item.otherUser.firstname} ${item.otherUser.lastname}`,
              picture: `${constants.mediaURL}${item.otherUser.picture}`,
            });
            navigate(`/message/${item.otherUser.id}/${item.id}`);
          }}
        >
          <List.Item.Meta
            style={{
              alignItems: 'center',
            }}
            avatar={
              <Avatar src={`${constants.mediaURL}${item.otherUser.picture}`} />
            }
            title={`${item.otherUser.firstname} ${item.otherUser.lastname}`}
          />
        </List.Item>
      )}
    />
  );
};
