import React from 'react';
import { constants } from '../../utils/constants';
import Meta from 'antd/es/card/Meta';
import relativeTime from 'dayjs/plugin/relativeTime';
import './PostModal.scss';
import { Avatar } from 'antd';
import dayjs from 'dayjs';
import { CommentInput } from '../Comment/Comment';

export const PostModal = ({ post, user, updateComment }) => {
  dayjs.extend(relativeTime);

  return (
    <div className='post-modal'>
      <div className='post-modal-image'>
        {post.filetype === 'video/mp4' ? (
          <video controls autoPlay={Boolean(post)}>
            <source
              src={`${constants.mediaURL}${post.filename}`}
              type='video/mp4'
            />
          </video>
        ) : (
          <img src={`${constants.mediaURL}${post.filename}`} alt='Bonvue' />
        )}
      </div>
      <div className='post-modal-comments'>
        <div>
          <Meta
            avatar={
              <Avatar size={44} src={`${constants.mediaURL}${user.picture}`} />
            }
            title={
              <span
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '15px',
                }}
              >
                {`${user.firstname} ${user.lastname}`}
              </span>
            }
            description={
              <span style={{ fontSize: '16px', color: '#595959' }}>
                {dayjs(post.createdAt).fromNow()}
              </span>
            }
          />
          <p>{post.description}</p>
        </div>

        <div>
          <CommentInput post={post} updateComment={updateComment} />
        </div>
      </div>
    </div>
  );
};
