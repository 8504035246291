import React, { useState } from 'react';
import dayjs from 'dayjs';
import './UserStory.scss';
import { Avatar, Dropdown, Divider, Space, Image, Modal } from 'antd';
import Meta from 'antd/es/card/Meta';
import {
  HeartFilled,
  MessageOutlined,
  EllipsisOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from '@ant-design/icons';
import { constants } from '../../utils/constants';
import { PostModal } from '../../components/PostModal/PostModal';
import { useIsMobile } from '../../utils/hooks';
import { BottomSheetComment } from '../../components/Comment/BottomSheetComment';
import { likePost, unlikePost } from '../../api/api';
import { useRecoilValue } from 'recoil';
import { user } from '../../store/user';
import { useNavigate } from 'react-router';
import { DoubleTap } from '../../components/DoubleTap/DoubleTap';
const IconText = ({ icon, text, likedByUser }) => (
  <Space>
    {React.createElement(icon, {
      style: { color: likedByUser ? '#7b61ff' : 'inherit' },
    })}
    {text}
  </Space>
);

const UserStory = ({
  post,
  isOwner,
  updateLike,
  updateComment,
  deleteUserPost,
  deletes,
  profile,
}) => {
  const [openModal, setOpenModal] = useState(null);
  const [deletePost, setDeletePost] = useState(null);
  const userData = useRecoilValue(user);
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const onLike = () => {
    if (post.likedByUser) {
      const like = post.likes.find((like) => like.userId === userData.id);
      unlikePost(post.id, like.id).then(() => {
        updateLike(like, false);
      });
    } else {
      likePost({ postId: post.id }).then((res) => {
        updateLike(res?.data?.results, true);
      });
    }
  };

  return (
    <div className='user-story'>
      <div className='user-story-header'>
        <Meta
          onClick={() =>
            navigate(`/public-profile/${post.authorId}`, {
              state: {
                name: `${post.author.firstname} ${post.author.lastname}`,
              },
            })
          }
          avatar={
            <Avatar
              size={44}
              src={`${constants.mediaURL}${post.author.picture}`}
            />
          }
          title={
            <span
              style={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '15px',
              }}
            >
              {`${post.author.firstname} ${post.author.lastname}`}
            </span>
          }
        />
        <div>
          {deletePost && deletePost === post.id ? (
            <div>
              <CheckCircleFilled
                onClick={() => deleteUserPost(post.id)}
                style={{
                  color: 'green',
                  fontSize: 20,
                  cursor: 'pointer',
                }}
              />
              <CloseCircleFilled
                onClick={() => setDeletePost(null)}
                style={{
                  color: 'red',
                  fontSize: 20,
                  marginLeft: 10,
                  cursor: 'pointer',
                }}
              />
            </div>
          ) : (
            <>
              <span className='user-story-timeline'>
                {dayjs(post.createdAt).fromNow()}
              </span>
              {isOwner && deletes && (
                <Dropdown
                  menu={{
                    items: [
                      {
                        label: (
                          <span onClick={() => setDeletePost(post.id)}>
                            Delete post
                          </span>
                        ),
                        key: '0',
                      },
                    ],
                  }}
                  trigger={['click']}
                >
                  <EllipsisOutlined />
                </Dropdown>
              )}
            </>
          )}
        </div>
      </div>

      <div>
        <DoubleTap
          type={post.filetype}
          src={`${constants.mediaURL}${post.filename}`}
          onDoubleTap={onLike}
        />
      </div>
      <div className='user-story-footer'>
        {/* <IconText icon={DollarOutlined} text='156' />
        <Divider type='vertical' /> */}

        <div onClick={onLike} style={{ cursor: 'pointer' }}>
          <IconText
            icon={HeartFilled}
            text={post.likes.length}
            likedByUser={post.likedByUser}
          />
        </div>
        <Divider type='vertical' />
        <div onClick={() => setOpenModal(post)} style={{ cursor: 'pointer' }}>
          <IconText icon={MessageOutlined} text={post.comments.length} />
        </div>
      </div>

      {isMobile ? (
        <BottomSheetComment
          isOpen={Boolean(openModal)}
          closeSheet={() => setOpenModal(null)}
          post={post}
          updateComment={updateComment}
        />
      ) : (
        <Modal
          className='post-modal-user'
          footer={null}
          centered
          open={Boolean(openModal)}
          onCancel={() => {
            setOpenModal(null);
            Modal.destroyAll();
          }}
          destroyOnClose={true}
        >
          <PostModal
            post={openModal}
            user={openModal?.author}
            updateComment={updateComment}
          />
        </Modal>
      )}
    </div>
  );
};

export default UserStory;
