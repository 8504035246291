import { Input, Button, notification, Checkbox } from 'antd';
import React, { useState, useEffect } from 'react';
import { addNewPost } from '../../../../api/api';

import './AddPost.scss';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { appHeader } from '../../../../store/header';
import { getImageDimensions, getVideoDimensions } from './utils/helpers';

export const AddPost = () => {
  const setHeader = useSetRecoilState(appHeader);
  const [file, setFile] = useState(null);
  const [subsOnly, setSubsOnly] = useState(false);
  const [description, setDescription] = useState('');
  const [base64String, setBase64String] = useState('');
  const [videoBase64, setVideoBase64] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setHeader({ name: 'New post' });
  }, []);

  const onSave = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('description', description);
    formData.append('subsciptionsonly', subsOnly ? 1 : 0);

    const newPost = await addNewPost(formData);

    if (newPost.results) {
      notification.success({ message: 'Post is successfully added!' });
      navigate('/profile');
    }

    setLoading(false);
  };

  const handleFileSelect = (event) => {
    const files = event.target.files[0];
    setBase64String('');
    setVideoBase64('');
    if (files.type.includes('image')) {
      getImageDimensions(files)
        .then((dimensions) => {
          if (
            Number(dimensions.width) < 500 ||
            Number(dimensions.height) < 500
          ) {
            notification.warning({
              message: 'Image size to small.',
              description: 'Image size should be 500x500 or bigger.',
            });
          } else {
            setFile(files);
            const reader = new FileReader();

            reader.onload = function (event) {
              const base64String = event.target.result;
              setBase64String(base64String);
            };

            reader.readAsDataURL(files);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    } else {
      getVideoDimensions(files)
        .then((dimensions) => {
          if (
            Number(dimensions.width) < 500 ||
            Number(dimensions.height) < 500
          ) {
            notification.warning({
              message: 'Video size to small.',
              description: 'Video size should be 500x500 or bigger.',
            });
          } else {
            setFile(files);
            const reader = new FileReader();

            reader.onload = function (event) {
              const base64String = event.target.result;
              setVideoBase64(base64String);
            };

            reader.readAsDataURL(files);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  };

  const onChange = (e) => {
    setSubsOnly(e.target.checked);
  };

  return (
    <div style={{ padding: '30px 20px' }}>
      <div className='uploadPost'>
        <label htmlFor='fileUpload' className='labelForUpload'>
          {base64String ? (
            <img src={base64String} />
          ) : videoBase64 ? (
            <video style={{ width: '100px', height: '100px' }}>
              <source src={videoBase64} type='video/mp4' />
            </video>
          ) : (
            <span>Upload file</span>
          )}
          <input
            accept='image/*, video/*'
            id='fileUpload'
            type='file'
            style={{ display: 'none' }}
            onChange={handleFileSelect}
          />
        </label>
      </div>
      {/* <br />
      <br />
      <Checkbox checked={subsOnly} onChange={onChange}>
        For subscirbers only
      </Checkbox> */}
      <br />
      <br />
      <Input.TextArea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        rows={4}
        placeholder='Description'
        maxLength={100}
      />
      <br />
      <br />
      <Button
        disabled={!file}
        loading={loading}
        type='primary'
        onClick={() => onSave()}
      >
        Add post
      </Button>
    </div>
  );
};
