import React, { useEffect, useState } from 'react';
import UserStory from './UserStory';
import './StoryWrapper';
import { getAllUsersPosts, getFollowingPosts } from '../../api/api';
import { Spin, Empty } from 'antd';
import { Virtuoso } from 'react-virtuoso';
import { useRecoilValue } from 'recoil';
import { user } from '../../store/user';
import { useIsMobile } from '../../utils/hooks';

const StoryWrapper = ({ all }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const userData = useRecoilValue(user);
  const isMobile = useIsMobile();

  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = async () => {
    let allPosts;

    if (all) {
      allPosts = await getAllUsersPosts(userData.usertype);
    } else {
      allPosts = await getFollowingPosts();
    }

    if (allPosts?.results) {
      setPosts(allPosts?.results);
    }

    setLoading(false);
  };

  const updateLike = (like, isLike) => {
    const updatedPosts = [...posts];

    updatedPosts.map((post) => {
      if (post.id === like.postId) {
        post.likedByUser = isLike;

        if (isLike) {
          post.likes.push(like);
        } else {
          const likeIndex = post.likes.findIndex((l) => l.id === like.id);
          post.likes.splice(likeIndex, 1);
        }
      }
    });

    setPosts(updatedPosts);
  };

  const updateComment = (comment) => {
    const updatedPosts = [...posts];

    updatedPosts.map((post) => {
      if (post.id === comment.postId) {
        post.comments.push(comment);
      }
    });

    setPosts(updatedPosts);
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Spin size='large' />
      </div>
    );
  }

  if (!posts.length) {
    return (
      <Empty description={<span>Follow someone to see more posts</span>} />
    );
  }

  return (
    <div>
      <Virtuoso
        style={{ height: isMobile ? 'calc(100vh - 174px)' : '100vh' }}
        data={posts}
        overscan={2000}
        itemContent={(i, post) => (
          <UserStory
            post={post}
            updateLike={updateLike}
            updateComment={updateComment}
          />
        )}
      />
    </div>
  );
};

export default StoryWrapper;
