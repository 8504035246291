import React, { useEffect, useState } from 'react';
import { Image, Button, Space, Modal } from 'antd';
import CountUp from 'react-countup';
import {
  EditOutlined,
  EnvironmentOutlined,
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  UploadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Breakpoint } from 'react-socks';

import './Profile.scss';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isUserLoggedIn, user } from '../../../../store/user';
import {
  getAllPosts,
  getUserFollowers,
  uploadProfilePic,
} from '../../../../api/api';
import { constants } from '../../../../utils/constants';
import { profilePosts } from '../../../../store/profile';
import { UpdateProfile } from './components/UpdateProfile/UpdateProfile';
import { appHeader } from '../../../../store/header';
import { Posts } from './components/Posts/Posts';
import { useIsMobile } from '../../../../utils/hooks';
import { useNavigate } from 'react-router-dom';

export const Profile = () => {
  const userData = useRecoilValue(user);
  const isLoggedIn = useRecoilValue(isUserLoggedIn);
  const setHeader = useSetRecoilState(appHeader);
  const setProfilePosts = useSetRecoilState(profilePosts);
  const setUserData = useSetRecoilState(user);
  const [posts, setPosts] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [updateUser, setUpdatUser] = useState(false);
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  useEffect(() => {
    getUserPosts();
    getUserFollowingFollowers();
    setHeader({ name: 'My profile' });
  }, []);

  const getUserPosts = async () => {
    const posts = await getAllPosts();

    if (posts?.results) {
      setProfilePosts(posts?.results);
      setPosts(posts?.results);
    }
  };

  const getUserFollowingFollowers = async () => {
    const followers = await getUserFollowers();

    if (followers?.results) {
      setFollowers(followers?.results.followers);
    }
  };

  const updateLocalUser = (data) => {
    const parsedUser = JSON.parse(localStorage.getItem('user'));
    localStorage.setItem('user', JSON.stringify({ ...parsedUser, ...data }));
    setUserData({ ...user, ...data });
  };

  const onFileUpload = async (type) => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.style.display = 'none';

    // You can append the file input to a container if needed
    document.body.appendChild(fileInput);

    fileInput.click();

    // You can also use this function to perform actions after a file is selected
    fileInput.addEventListener('change', async () => {
      const selectedFile = fileInput.files[0];
      // Handle the selected file here
      const formData = new FormData();
      formData.append(type, selectedFile);

      const updatedImage = await uploadProfilePic(formData);
      updateLocalUser(updatedImage?.results);
      // Remove the dynamically created input element if necessary
      fileInput.remove();
    });
  };

  const imageToolbar = (
    onFlipY,
    onFlipX,
    onRotateLeft,
    onRotateRight,
    onZoomOut,
    onZoomIn,
    scale,
    type
  ) => {
    return (
      <Space size={12} className='toolbar-wrapper'>
        {isLoggedIn && <UploadOutlined onClick={() => onFileUpload(type)} />}
        <SwapOutlined rotate={90} onClick={onFlipY} />
        <SwapOutlined onClick={onFlipX} />
        <RotateLeftOutlined onClick={onRotateLeft} />
        <RotateRightOutlined onClick={onRotateRight} />
        <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
        <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
      </Space>
    );
  };

  return (
    <div className='profile'>
      <div>
        <Image
          className='profile-cover-image'
          width={'100%'}
          src={`${constants.mediaURL}${userData.cover}`}
          fallback={require('../../../../assets/images/profile-wall.png')}
          preview={{
            toolbarRender: (
              _,
              {
                transform: { scale },
                actions: {
                  onFlipY,
                  onFlipX,
                  onRotateLeft,
                  onRotateRight,
                  onZoomOut,
                  onZoomIn,
                },
              }
            ) =>
              imageToolbar(
                onFlipY,
                onFlipX,
                onRotateLeft,
                onRotateRight,
                onZoomOut,
                onZoomIn,
                scale,
                'cover'
              ),
          }}
        />
      </div>

      <div className='profile-info'>
        <Image
          width={120}
          height={120}
          style={{ borderRadius: '15px', objectFit: 'cover' }}
          src={`${constants.mediaURL}${userData.picture}`}
          fallback='https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
          preview={{
            toolbarRender: (
              _,
              {
                transform: { scale },
                actions: {
                  onFlipY,
                  onFlipX,
                  onRotateLeft,
                  onRotateRight,
                  onZoomOut,
                  onZoomIn,
                },
              }
            ) =>
              imageToolbar(
                onFlipY,
                onFlipX,
                onRotateLeft,
                onRotateRight,
                onZoomOut,
                onZoomIn,
                scale,
                'picture'
              ),
          }}
        />
        <div className='profile-info-user'>
          <div>
            <h3
              style={{ color: '#A069E2' }}
            >{`${userData.firstname} ${userData.lastname}`}</h3>
            {userData.location && (
              <h3>
                <EnvironmentOutlined /> {userData.location}
              </h3>
            )}
          </div>
          <Breakpoint customQuery='(min-width: 768px)' className='left-panel'>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div>
                <h3 style={{ color: '#A069E2' }}>Fans</h3>
                <h3>
                  <CountUp end={followers.length} duration={2} />
                </h3>
              </div>
              <div style={{ marginLeft: '15px' }}>
                <h3 style={{ color: '#A069E2' }}>Total posts</h3>
                <h3>
                  <CountUp end={posts.length} duration={2} />
                </h3>
              </div>
            </div>
          </Breakpoint>
        </div>
      </div>

      <Breakpoint customQuery='(max-width: 768px)' className='left-panel'>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div style={{ textAlign: 'center' }}>
            <h3 style={{ color: '#A069E2' }}>Fans</h3>
            <h3>
              <CountUp end={followers.length} duration={2} />
            </h3>
          </div>
          <div style={{ marginLeft: '15px', textAlign: 'center' }}>
            <h3 style={{ color: '#A069E2' }}>Total posts</h3>
            <h3>
              <CountUp end={posts.length} duration={2} />
            </h3>
          </div>
        </div>
      </Breakpoint>

      <div
        style={{
          marginTop: '15px',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 10px',
        }}
      >
        <div style={{ display: 'flex' }}>
          {userData.instagram && (
            <a href={userData.instagram} target='_blank' rel='noreferrer'>
              <Button
                style={{ marginRight: '10px' }}
                type='primary'
                icon={<InstagramOutlined />}
              />
            </a>
          )}
          {userData.twitter && (
            <a href={userData.twitter} target='_blank' rel='noreferrer'>
              <Button
                style={{ marginRight: '10px' }}
                type='primary'
                icon={<TwitterOutlined />}
              />
            </a>
          )}
          {userData.facebook && (
            <a href={userData.facebook} target='_blank' rel='noreferrer'>
              <Button type='primary' icon={<FacebookOutlined />} />
            </a>
          )}
        </div>
        <Button
          type='primary'
          icon={<EditOutlined />}
          onClick={() =>
            isMobile ? navigate('/update-profile') : setUpdatUser(true)
          }
        >
          Edit profile
        </Button>
      </div>

      <div className='profile-bio'>{userData.bio}</div>
      <div className='profile-tab-content'>
        <Posts />
      </div>

      <Modal
        footer={null}
        centered
        open={updateUser}
        onCancel={() => {
          setUpdatUser();
          Modal.destroyAll();
        }}
        destroyOnClose={true}
      >
        <UpdateProfile />
      </Modal>
    </div>
  );
};
