import { atom } from 'recoil';

export const profilePosts = atom({
  key: 'profilePosts',
  default: null,
});

export const userFollowers = atom({
  key: 'followers',
  default: null,
});
