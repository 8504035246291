import { Button, Form, Input, List, Spin } from 'antd';
import { Comment } from '@ant-design/compatible';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { SendOutlined } from '@ant-design/icons';

import './Comment.scss';
import { addNewComment, getAllComments } from '../../api/api';
import { constants } from '../../utils/constants';

const { TextArea } = Input;

const CommentList = ({ comments }) => {
  const postHieght = document
    .getElementsByClassName('post-modal-image')[0]
    .getBoundingClientRect();
  console.log(postHieght);
  return (
    <div
      className='comment-list'
      style={{ height: `${postHieght.height - 200}px` }}
    >
      {comments.map((comment) => (
        <Comment {...comment} />
      ))}
    </div>
  );
};

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item style={{ margin: 0 }}>
      <TextArea
        style={{ paddingRight: '50px' }}
        rows={2}
        onChange={onChange}
        value={value}
      />
    </Form.Item>
    <Form.Item className='custom-send'>
      <SendOutlined onClick={onSubmit} style={{ fontSize: '20px' }} />
    </Form.Item>
  </>
);

export const CommentInput = ({ post, updateComment }) => {
  const [comments, setComments] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getComments();
  }, []);

  const getComments = async () => {
    const allComments = await getAllComments(post.id);

    if (allComments?.results) {
      const newComments = allComments?.results.map((comment) => {
        return {
          author: `${comment.user.firstname} ${comment.user.lastname}`,
          avatar: `${constants.mediaURL}${comment?.user.picture}`,
          content: <p>{comment.comment}</p>,
          datetime: dayjs(comment.createdAt).fromNow(),
        };
      });

      setComments(newComments);
    }

    setLoading(false);
  };

  const handleSubmit = async () => {
    if (!value) return;
    setSubmitting(true);

    const addComment = await addNewComment({
      comment: value,
      parentId: null,
      postId: post.id,
    });

    if (addComment?.results) {
      const comment = addComment?.results;
      updateComment(comment);
      setComments([
        {
          author: `${comment.user.firstname} ${comment.user.lastname}`,
          avatar: `${constants.mediaURL}${comment?.user.picture}`,
          content: <p>{comment.comment}</p>,
          datetime: dayjs(comment.createdAt).fromNow(),
        },
        ...comments,
      ]);
    }

    setSubmitting(false);
    setValue('');
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <>
      {loading ? (
        <Spin size='large' />
      ) : comments.length > 0 ? (
        <CommentList comments={comments} />
      ) : (
        'No comments, be the first'
      )}

      <Comment
        className='custom-comment'
        content={
          <Editor
            onChange={handleChange}
            onSubmit={handleSubmit}
            submitting={submitting}
            value={value}
          />
        }
      />
    </>
  );
};
