import React from 'react';
import { Avatar, Layout, Switch } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import { Link, Outlet } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './MiddlePanel.scss';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { appHeader } from '../../store/header';
import {
  registerNotifications,
  unregisterNotifications,
} from '../../utils/notifications';
import { user } from '../../store/user';
import ThemeSwitch from '../../components/ThemeSwitch/ThemeSwitch';

const MiddlePanel = () => {
  const { name, picture, notifications, theme } = useRecoilValue(appHeader);
  const userData = useRecoilValue(user);
  const setUser = useSetRecoilState(user);

  const onChange = (checked) => {
    if (checked) {
      registerNotifications(userData.id).then((e) => {
        const updateUser = { ...userData, notifications: true };
        setUser(updateUser);
        localStorage.setItem('user', JSON.stringify(updateUser));
      });
    } else {
      unregisterNotifications().then((e) => {
        const updateUser = { ...userData, notifications: false };
        setUser(updateUser);
        localStorage.setItem('user', JSON.stringify(updateUser));
      });
    }
  };

  return (
    <Layout className='middle-section'>
      <Header className='header-wrapper'>
        <div className='header-link'>
          {picture && (
            <Link to={'/messages'} style={{ textDecoration: 'none' }}>
              <ArrowLeftOutlined />
            </Link>
          )}

          {picture && <Avatar src={picture} />}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Link style={{ textDecoration: 'none' }}>{name}</Link>

            {notifications && (
              <Switch checked={userData.notifications} onChange={onChange} />
            )}

            {theme && <ThemeSwitch />}
          </div>
        </div>
      </Header>
      <Content className='content-wrapper'>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default MiddlePanel;
