import { atom } from 'recoil';

export const user = atom({
  key: 'user',
  default: null,
});

export const isUserLoggedIn = atom({
  key: 'logged',
  default: null,
});
