import React, { useState, useEffect, useRef } from 'react';
import update from 'immutability-helper';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { user } from '../../store/user';
import { Button, Input } from 'antd';
import { SendOutlined } from '@ant-design/icons';

import './Chat.scss';

import { Virtuoso } from 'react-virtuoso';
import { ChatRow } from './components/ChatRow/ChatRow';
import { socket } from '../../api/socket';
import { appHeader } from '../../store/header';

// Create the socket connection outside of the component

const Chat = () => {
  const { otherUser, channelId } = useParams();
  const header = useRecoilValue(appHeader);
  const setHeader = useSetRecoilState(appHeader);
  const userData = useRecoilValue(user);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const virtuoso = useRef(null);

  useEffect(() => {
    if (!header?.name) {
      setHeader({
        name: 'Chat',
        picture:
          'https://bonvue.nyc3.digitaloceanspaces.com/media/users/images/c9f7f4f4-4eae-4185-a196-a2c77d80470a',
      });
    }

    socket.emit('join', { channelId: channelId });

    socket.on('message', (msg) => {
      console.log(msg);
      if (msg?.success?.messages) {
        setMessages(msg?.success?.messages);
      }

      if (msg.content) {
        setMessages((prevMessages) => update(prevMessages, { $push: [msg] }));
      }
    });

    socket.on('connect_error', (error) => {
      console.error('Connection Error:', error);
    });

    return () => {
      socket.emit('leaveRoom', { channelId: channelId });
    };
  }, []);

  useEffect(() => {
    if (virtuoso.current) {
      virtuoso.current.scrollToIndex({
        index: messages.length - 1,
        align: 'end',
        behavior: 'auto',
      });
    }
  }, [messages]);

  const sendMessage = () => {
    const payload = {
      channelId: channelId,
      content: message,
      otherUser: otherUser,
      sender: {
        firstname: userData.firstname,
        lastname: userData.lastname,
        picture: userData.picture,
        id: userData.id,
      },
      createdAt: new Date(),
    };

    socket.emit('message', payload);
    setMessages((prevMessages) => [...prevMessages, payload]);
    setMessage('');
  };

  return (
    <div className='chat'>
      <ul id='chatContent'>
        <Virtuoso
          ref={virtuoso}
          totalCount={messages.length}
          initialTopMostItemIndex={messages.length - 1}
          followOutput={'auto'}
          id='chatContentVL'
          style={{ height: '100%' }}
          data={messages}
          itemContent={(index, msg) => <ChatRow message={msg} />}
        />
      </ul>
      <div className='chat-input'>
        <Input.TextArea
          onKeyUp={(e) => {
            e.preventDefault();

            if (e.key === 'Enter') {
              sendMessage(e.target.value);
            }
          }}
          type='text'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          style={{ borderRadius: 0, fontSize: '17px' }}
        />
        <Button style={{ height: '100%' }} type='primary' onClick={sendMessage}>
          <SendOutlined />
        </Button>
      </div>
    </div>
  );
};

export default Chat;
