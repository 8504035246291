import { addNotifications, removeNotifications } from '../api/api';
import { urlB64ToUint8Array } from './helpers';

export const registerNotifications = async (userId) => {
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      if ('serviceWorker' in navigator && 'PushManager' in window) {
        navigator.serviceWorker
          .register('/service-worker.js')
          .then((registration) => {
            console.log(
              'Service Worker registered with scope:',
              registration.scope
            );

            const applicationServerKey = urlB64ToUint8Array(
              'BKBcaYK5PA4aYqu-G_PLLvGuJyzYV0-rBw0NuCRGTSvVu4syfJ8ogvuRpo0LJa2XBkO3xwZeQNyNBxZJTxOcLJw'
            );
            const options = { applicationServerKey, userVisibleOnly: true };

            registration.pushManager
              .subscribe(options)
              .then(async (subscription) => {
                await addNotifications({
                  userId,
                  subscription,
                });
              });
          });
      }
    } else {
      console.log('Notification permission denied');
    }
  });
};

export const unregisterNotifications = async (userId) => {
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      if ('serviceWorker' in navigator && 'PushManager' in window) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.pushManager
            .getSubscription()
            .then(async (subscription) => {
              if (subscription) {
                await removeNotifications();
                return subscription.unsubscribe();
              }
            });
        });
      }
    } else {
      console.log('Notification permission denied');
    }
  });
};
