import React from 'react';
import Sider from 'antd/es/layout/Sider';
import './RightPanel.scss';
import Title from 'antd/es/typography/Title';
import { Link } from 'react-router-dom';
import Suggestion from './Suggestion';
import AdWrapper from './AdWrapper';
import { useRecoilValue } from 'recoil';
import { suggestions } from '../../store/suggestions';
import { SearchCreators } from '../../components/Search/Search';

const RightPanel = () => {
  const suggestedUsers = useRecoilValue(suggestions);

  return (
    <Sider
      className='right-panel-wrapper'
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 11,
      }}
    >
      <SearchCreators />
      <div className='suggestions-wrapper'>
        <div className='titles-wrapper'>
          <Title level={4}>Suggestions</Title>
          <Link className='special-link'>
            <Title level={5}>View All</Title>
          </Link>
        </div>

        {suggestedUsers.map((profile) => {
          return <Suggestion profile={profile} />;
        })}

        <AdWrapper />
      </div>
    </Sider>
  );
};

export default RightPanel;
