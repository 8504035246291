import React, { useEffect } from 'react';
import { List } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isUserLoggedIn, user } from '../../store/user';
import { appHeader } from '../../store/header';
import { userType } from '../../utils/constants';
import './MenuList.scss';

export const MenuList = () => {
  const setUser = useSetRecoilState(user);
  const setHeder = useSetRecoilState(appHeader);
  const isLoggedIn = useSetRecoilState(isUserLoggedIn);
  const navigate = useNavigate();
  const userData = useRecoilValue(user);

  const menu =
    userData.usertype === userType.user
      ? [
          { name: 'Notifications', path: '/notifications' },
          { name: 'Logout', path: '/logout' },
        ]
      : [
          { name: 'Notifications', path: '/notifications' },
          { name: 'My profile', path: '/profile' },
          { name: 'Logout', path: '/logout' },
        ];

  useEffect(() => {
    setHeder({ name: 'More', theme: true });
  }, []);

  return (
    <div>
      <List
        className='settings-list'
        dataSource={menu}
        renderItem={(item) => (
          <List.Item
            onClick={() => {
              if (item.path === '/logout') {
                setUser(null);
                isLoggedIn(false);
                localStorage.removeItem('user');
                navigate('/');
              } else {
                setHeder({ name: item.name });
                navigate(item.path);
              }
            }}
          >
            {item.name}
          </List.Item>
        )}
      />
    </div>
  );
};
