import React from 'react';

import { useRecoilValue } from 'recoil';
import Suggestion from '../../Suggestion';
import AdWrapper from '../../AdWrapper';
import { suggestions } from '../../../../store/suggestions';
import { SearchCreators } from '../../../../components/Search/Search';

export const Suggestions = () => {
  const suggestedUsers = useRecoilValue(suggestions);

  return (
    <div style={{ padding: '0 20px', marginBottom: '50px' }}>
      <SearchCreators />
      <div className='suggestions-wrapper' style={{ marginTop: '25px' }}>
        {suggestedUsers.map((profile) => {
          return <Suggestion profile={profile} />;
        })}

        <AdWrapper />
      </div>
    </div>
  );
};
