import React, { useState } from 'react';
import {
  CommentOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  LikeOutlined,
  HeartFilled,
} from '@ant-design/icons';
import { Empty, Modal } from 'antd';
import { PostModal } from '../PostModal/PostModal';
import { constants } from '../../utils/constants';
import dayjs from 'dayjs';

import { Dropdown, notification } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

import './PostContent.scss';
import { useIsMobile } from '../../utils/hooks';
import UserStory from '../../pages/Home/UserStory';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { profilePosts } from '../../store/profile';
import { deletePosts, likePost, unlikePost } from '../../api/api';
import { user as userState } from '../../store/user';

export const PostContent = ({ posts, user }) => {
  const [deletePost, setDeletePost] = useState(null);
  const [openModal, setOpenModal] = useState(null);
  const setPosts = useSetRecoilState(profilePosts);
  const userData = useRecoilValue(userState);
  const isMobile = useIsMobile();

  const clone = (obj) => JSON.parse(JSON.stringify(obj));

  const updateLike = (like, isLike) => {
    const updatedPosts = clone(posts);

    updatedPosts.map((post) => {
      if (post.id === like.postId) {
        console.log(post);
        post.likedByUser = isLike;

        if (isLike) {
          post.likes.push(like);
        } else {
          const likeIndex = post.likes.findIndex((l) => l.id === like.id);
          post.likes.splice(likeIndex, 1);
        }
      }
    });

    setPosts(updatedPosts);
  };

  const updateComment = (comment) => {
    const updatedPosts = clone(posts);

    updatedPosts.map((post) => {
      if (post.id === comment.postId) {
        post.comments.push(comment);
      }
    });
    setDeletePost(null);
    setPosts(updatedPosts);
  };

  const deleteUserPost = async (postId) => {
    deletePosts(postId).then(() => {
      const updatedPosts = clone(posts);
      const likeIndex = updatedPosts.findIndex((post) => post.id === postId);
      updatedPosts.splice(likeIndex, 1);

      notification.success({
        message: 'Post was successfully deleted.',
      });

      setPosts(updatedPosts);
    });
  };

  return (
    <div className='profile-content'>
      {posts.length ? (
        posts.map((post) => {
          if (isMobile) {
            return (
              <UserStory
                post={post}
                isOwner={userData.id === post.authorId}
                updateLike={updateLike}
                updateComment={updateComment}
                deleteUserPost={deleteUserPost}
                deletes
              />
            );
          } else {
            return (
              <div>
                {userData.id === post.authorId && (
                  <div className='user-story-header'>
                    {deletePost && deletePost === post.id ? (
                      <div>
                        <CheckCircleFilled
                          onClick={() => deleteUserPost(post.id)}
                          style={{
                            color: 'green',
                            fontSize: 20,
                            cursor: 'pointer',
                          }}
                        />
                        <CloseCircleFilled
                          onClick={() => setDeletePost(null)}
                          style={{
                            color: 'red',
                            fontSize: 20,
                            marginLeft: 10,
                            cursor: 'pointer',
                          }}
                        />
                      </div>
                    ) : (
                      <>
                        <span className='user-story-timeline'>
                          {dayjs(post.createdAt).fromNow()}
                        </span>

                        <Dropdown
                          menu={{
                            items: [
                              {
                                label: (
                                  <span onClick={() => setDeletePost(post.id)}>
                                    Delete post
                                  </span>
                                ),
                                key: '0',
                              },
                            ],
                          }}
                          trigger={['click']}
                        >
                          <EllipsisOutlined />
                        </Dropdown>
                      </>
                    )}
                  </div>
                )}

                <div className='profile-media'>
                  <div
                    className='profile-media-hover'
                    onClick={() => setOpenModal(post)}
                  >
                    <>
                      <CommentOutlined
                        style={{ color: 'white', fontSize: '25px' }}
                      />
                      <span className='count-up'>{post.comments.length}</span>
                    </>

                    <>
                      <HeartFilled
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          if (post.likedByUser) {
                            const like = post.likes.find(
                              (like) => like.userId === userData.id
                            );
                            unlikePost(post.id, like.id).then(() => {
                              updateLike(like, false);
                            });
                          } else {
                            likePost({ postId: post.id }).then((res) => {
                              updateLike(res?.data?.results, true);
                            });
                          }
                        }}
                        style={{ color: 'white', fontSize: '25px' }}
                      />
                      <span className='count-up'>{post.likes.length}</span>
                    </>
                  </div>
                  {post.filetype === 'video/mp4' ? (
                    <video>
                      <source
                        src={`${constants.mediaURL}${post.filename}`}
                        type='video/mp4'
                      />
                    </video>
                  ) : (
                    <img
                      alt='Bonvue'
                      src={`${constants.mediaURL}${post.filename}`}
                    />
                  )}
                </div>
              </div>
            );
          }
        })
      ) : (
        <Empty description={<span>No post yet</span>} />
      )}

      <Modal
        className='post-modal-user'
        footer={null}
        centered
        open={Boolean(openModal)}
        onCancel={() => {
          setOpenModal(null);
          Modal.destroyAll();
        }}
        destroyOnClose={true}
      >
        <PostModal
          post={openModal}
          user={user || openModal?.author}
          updateComment={updateComment}
        />
      </Modal>
    </div>
  );
};
