import React from 'react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { useSetRecoilState } from 'recoil';

import './Social.css';
import { user } from '../../../store/user';
import axiosInstance from '../../../utils/axios';

export const GoogleAuth = ({ handleCloseModal }) => {
  const setUser = useSetRecoilState(user);
  const handleGoogleSuccess = (response) => {
    // Send the response to the backend for further processing
    sendTokenToBackend(response.credential);
  };

  const handleGoogleFailure = (error) => {
    console.log('Google login failed:');
    // Handle error or perform any necessary actions
  };

  const sendTokenToBackend = (accessToken) => {
    axiosInstance
      .post('api/v1/auth/google', {
        code: accessToken,
      })
      .then((res) => {
        if (res?.data?.results) {
          localStorage.setItem('user', JSON.stringify(res.data.results));
          setUser(res?.data?.results);
          handleCloseModal();
        }
      });
  };

  const innerWidth = document.getElementsByClassName('right-container');

  return (
    <div style={{ marginTop: '30px' }}>
      <GoogleOAuthProvider clientId=''>
        <GoogleLogin
          width='300px'
          text='continue_with'
          onSuccess={handleGoogleSuccess}
          onFailure={handleGoogleFailure}
          cookiePolicy={'single_host_origin'}
        />
      </GoogleOAuthProvider>
      <div style={{ margin: '30px 0' }} />
    </div>
  );
};
