import React, { useState } from 'react';
import { Avatar, List, Empty, Spin } from 'antd';
import { constants } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getUserFollowers } from '../../api/api';

export const Following = () => {
  const [following, setFollowing] = useState([]);
  const [loading, setLoading] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getUserFollowingFollowers();
  }, []);

  const getUserFollowingFollowers = async () => {
    const following = await getUserFollowers();
    console.log(following);
    if (following?.results) {
      setFollowing(following?.results.following);
    }

    setLoading(false);
  };
  console.log(following);
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Spin size='large' />
      </div>
    );
  }

  if (!following.length) {
    return <Empty description='Your are not following anyone' />;
  }

  return (
    <List
      itemLayout='horizontal'
      dataSource={following}
      renderItem={(item, index) => (
        <List.Item
          style={{ cursor: 'pointer' }}
          onClick={() =>
            navigate(`/public-profile/${item.following.id}`, {
              state: {
                name: `${item.following.firstname} ${item.following.lastname}`,
              },
            })
          }
        >
          <List.Item.Meta
            style={{
              alignItems: 'center',
            }}
            avatar={
              <Avatar src={`${constants.mediaURL}${item.following.picture}`} />
            }
            title={
              <span>{`${item.following.firstname} ${item.following.lastname}`}</span>
            }
          />
        </List.Item>
      )}
    />
  );
};
