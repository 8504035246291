import React, { useEffect, useState } from 'react';
import { Avatar, List, Empty, Spin } from 'antd';
import { constants } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { getSubscriptions } from '../../api/api';
import { useRecoilValue } from 'recoil';

import { user } from '../../store/user';

export const Subscriptions = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState([]);
  const navigate = useNavigate();
  const userData = useRecoilValue(user);

  useEffect(() => {
    getAllSubscriptions();
  }, []);

  const getAllSubscriptions = async () => {
    const subs = await getSubscriptions(userData.usertype);

    if (subs?.results) {
      setSubscriptions(subs?.results);
    }

    setLoading(false);
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Spin size='large' />
      </div>
    );
  }

  if (!subscriptions.length) {
    return <Empty description='Your are not subscribed to any creator' />;
  }

  return (
    <List
      itemLayout='horizontal'
      dataSource={subscriptions}
      renderItem={(item, index) => {
        const sub = item?.subscriber || item?.subscribed;
        return (
          <List.Item
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (userData.usertype === 'USER') {
                navigate(`/public-profile/${sub.id}`, {
                  state: {
                    name: `${sub.firstname} ${sub.lastname}`,
                  },
                });
              }
            }}
          >
            <List.Item.Meta
              style={{ alignItems: 'center' }}
              avatar={<Avatar src={`${constants.mediaURL}${sub.picture}`} />}
              title={<span>{`${sub.firstname} ${sub.lastname}`}</span>}
            />
          </List.Item>
        );
      }}
    />
  );
};
