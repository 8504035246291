export const getImageDimensions = (file) => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();

    reader.onload = function (event) {
      var img = new Image();

      img.onload = function () {
        resolve({ width: img.width, height: img.height });
      };

      img.onerror = function () {
        reject(
          'Invalid image. Please ensure the file is an image and try again.'
        );
      };

      img.src = event.target.result;
    };

    reader.onerror = function () {
      reject('There was an error reading the file.');
    };

    reader.readAsDataURL(file);
  });
};

export const getVideoDimensions = (file) => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();

    reader.onload = function (event) {
      var video = document.createElement('video');

      video.onloadedmetadata = function () {
        resolve({ width: this.videoWidth, height: this.videoHeight });
      };

      video.onerror = function () {
        reject(
          'Invalid video. Please ensure the file is a video and try again.'
        );
      };

      video.src = event.target.result;
    };

    reader.onerror = function () {
      reject('There was an error reading the file.');
    };
    console.log(file, typeof file);
    reader.readAsDataURL(file);
  });
};
