import React, { useEffect, useState } from 'react';
import { getSuggestedUsers, searchCreator } from '../../api/api';
import { useSetRecoilState } from 'recoil';
import { suggestions } from '../../store/suggestions';
import { Input } from 'antd';
import { useIsMobile } from '../../utils/hooks';

export const SearchCreators = () => {
  const [searchText, setSeachText] = useState('');
  const setSuggestedUsers = useSetRecoilState(suggestions);
  const isMobile = useIsMobile();

  useEffect(() => {
    getSuggestedProfiles();
  }, []);

  const getSuggestedProfiles = async () => {
    const suggestedProfiles = await getSuggestedUsers();
    if (suggestedProfiles?.results) {
      setSuggestedUsers(suggestedProfiles?.results);
    }
  };

  useEffect(() => {
    getSearchCreator(searchText);
  }, [searchText]);

  const getSearchCreator = async (user) => {
    if (user.length >= 3) {
      const creatorsList = await searchCreator(user);

      if (creatorsList?.results) {
        setSuggestedUsers(creatorsList?.results);
      }
    } else {
      getSuggestedProfiles();
    }
  };

  return (
    <div className='search-wrapper'>
      <Input
        placeholder='Search creator'
        onChange={(e) => setSeachText(e.target.value)}
        allowClear
        style={{
          width: isMobile ? '100%' : 264,
        }}
      />
    </div>
  );
};
