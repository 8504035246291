import React, { useEffect } from 'react';
import { Tabs } from 'antd';

import './Feed.scss';

import CategoryWrapper from '../../CategoryWrapper';
import StoryWrapper from '../../StoryWrapper';
import { useIsMobile } from '../../../../utils/hooks';

import { Suggestions } from '../Suggestions/Suggestions';
import { useSetRecoilState } from 'recoil';
import { appHeader } from '../../../../store/header';

export const Feed = () => {
  const setHeader = useSetRecoilState(appHeader);
  const isMobile = useIsMobile();

  useEffect(() => {
    setHeader({ name: 'Feed' });
  }, []);

  const items = isMobile
    ? [
        {
          key: '1',
          label: `For you`,
          children: <StoryWrapper all />,
        },
        {
          key: '2',
          label: `Following`,
          children: <StoryWrapper />,
        },
        {
          key: '3',
          label: `Suggestions`,
          children: <Suggestions />,
        },
      ]
    : [
        {
          key: '1',
          label: `For you`,
          children: <StoryWrapper all />,
        },
        {
          key: '2',
          label: `Following`,
          children: <StoryWrapper />,
        },
      ];

  return (
    <Tabs
      rootClassName='feed-content'
      tabBarStyle={{ background: 'white', padding: '0 20px' }}
      className='feed-tabs'
      defaultActiveKey='1'
      items={items}
    />
  );
};
