import { Image } from 'antd';
import React, { useState, useRef } from 'react';

export const DoubleTap = ({ src, type, onDoubleTap }) => {
  const [lastTap, setLastTap] = useState(0);
  const timeout = useRef(null);

  const handleTouchEnd = () => {
    const currentTime = new Date().getTime();
    const tapLength = currentTime - lastTap;

    clearTimeout(timeout.current);

    if (tapLength < 500 && tapLength > 0) {
      onDoubleTap();
    } else {
      timeout.current = setTimeout(() => {
        clearTimeout(timeout.current);
      }, 500);
    }

    setLastTap(currentTime);
  };

  if (type === 'video/mp4') {
    return (
      <video onTouchEnd={handleTouchEnd} controls>
        <source src={src} type='video/mp4' />
      </video>
    );
  }

  return <Image onTouchEnd={handleTouchEnd} preview={false} src={src} />;
};
