import React from 'react';
import { Empty } from 'antd';
import { useRecoilValue } from 'recoil';
import { user } from '../../../../../../store/user';
import { profilePosts } from '../../../../../../store/profile';
import { PostContent } from '../../../../../../components/PostContent/PostContent';

export const Posts = () => {
  const userData = useRecoilValue(user);
  const posts = useRecoilValue(profilePosts);

  if (!posts) {
    return <Empty description={<span>No post yet</span>} />;
  }

  return <PostContent posts={posts} user={userData} />;
};
