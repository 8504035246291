import { Route, Routes } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { LogIn } from './pages/Authentication/LogIn/LogIn';
import { Register } from './pages/Authentication/Register/Register';
import { ForgotPassword } from './pages/Authentication/ForgotPassword/ForgotPassword';
import { ForgotPasswordConfirm } from './pages/Authentication/ForgotPassword/ForgotPasswordConfirm';
import { Home } from './pages/Home/Home';
import { Feed } from './pages/Home/components/Feed/Feed';
import { Profile } from './pages/Home/components/Profile/Profile';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isUserLoggedIn, user } from './store/user';
import { SplashScreen } from './pages/SplashScreen/SplashScreen';
import { AddPost } from './pages/Home/components/AddPost/AddPost';
import { PublicProfile } from './pages/PublicProfile/PublicProfile';
import Chat from './components/Chat/Chat';
import { ChatList } from './components/ChatList/Chatlist';
import { socket } from './api/socket';
import { MenuList } from './pages/MenuList/MenuList';
import { ConfigProvider, theme } from 'antd';
import { UpdateProfile } from './pages/Home/components/Profile/components/UpdateProfile/UpdateProfile';
import { Notifications } from './pages/Notifications/Notifications';
import { userType } from './utils/constants';
import { Creators } from './pages/Creators/Creators';
import { Fans } from './pages/Fans/Fans';
import { themesSwitchState } from './store/themeSwitch';

dayjs.extend(relativeTime);

function App() {
  const setUser = useSetRecoilState(user);
  const setIsLoggedIn = useSetRecoilState(isUserLoggedIn);
  const isLoggedIn = useRecoilValue(isUserLoggedIn);
  const userData = useRecoilValue(user);
  const themeSwitch = useRecoilValue(themesSwitchState);

  useEffect(() => {
    if (themeSwitch === 'dark') {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }

    localStorage.setItem('themesSwitchState', themeSwitch);
  }, [themeSwitch]);

  useEffect(() => {
    const userLocal = localStorage.getItem('user');
    if (userLocal) {
      const parsedUser = JSON.parse(userLocal);
      socket.auth = { userId: parsedUser.id };
      socket.connect();

      socket.on('connect_error', (error) => {
        console.error('Connection Error:', error);
      });

      setUser(parsedUser);
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }

    return () => {
      socket.disconnect();
    };
  }, []);

  const userRouts = (
    <Routes>
      <Route element={<Home />}>
        <Route element={<Feed />} path='/feed' />
        <Route element={<ChatList />} path='/messages' />
        <Route element={<Chat />} path='/message/:otherUser/:channelId' />
        <Route element={<PublicProfile />} path='/public-profile/:userId' />
        <Route element={<MenuList />} path='/more' />
        <Route element={<Creators />} path='/creators' />
        <Route element={<Notifications />} path='/notifications' />
        <Route element={<Feed />} index />
      </Route>
    </Routes>
  );

  const creatorRouts = (
    <Routes>
      <Route element={<Home />}>
        <Route element={<Feed />} path='/feed' />
        <Route element={<AddPost />} path='/new-post' />
        <Route element={<Profile />} path='/profile' />
        <Route element={<ChatList />} path='/messages' />
        <Route element={<Chat />} path='/message/:otherUser/:channelId' />
        <Route element={<PublicProfile />} path='/public-profile/:userId' />
        <Route element={<MenuList />} path='/more' />
        <Route element={<Fans />} path='/fans' />
        <Route element={<UpdateProfile />} path='/update-profile' />
        <Route element={<Notifications />} path='/notifications' />
        <Route element={<Feed />} index />
      </Route>
    </Routes>
  );

  if (isLoggedIn === null) {
    return <SplashScreen />;
  }

  if (isLoggedIn) {
    return (
      <ConfigProvider
        theme={{
          algorithm:
            themeSwitch === 'dark' ? theme.darkAlgorithm : theme.lightAlgorithm,
          token: {
            colorPrimary: '#A558C8',
          },
        }}
      >
        <div className='App'>
          {userData.usertype === userType.user ? userRouts : creatorRouts}
        </div>
      </ConfigProvider>
    );
  }

  return (
    <div className='App'>
      <Routes>
        <Route path='/register' element={<Register />} />
        <Route path='/login' element={<LogIn />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route
          path='/forgot-password/confirm/:token'
          element={<ForgotPasswordConfirm />}
        />
        <Route path='/' element={<LogIn />} />
        <Route path='*' element={<LogIn />} />
      </Routes>
    </div>
  );
}

export default App;
