import axiosInstance from '../utils/axios';

export const login = async (values) => {
  const response = await axiosInstance.post('api/v1/auth/login', values);
  return response.data;
};

export const register = async (values) => {
  const response = await axiosInstance.post('api/v1/auth/register', values);
  return response.data;
};

export const forgotpassword = async (values) => {
  const response = await axiosInstance.post(
    'api/v1/auth/forgot-password',
    values
  );
  return response.data;
};

export const forgotpasswordconfirm = async (values) => {
  const response = await axiosInstance.post(
    'api/v1/auth/forgot-password/confirm',
    values
  );
  return response.data;
};

export const getAllPosts = async () => {
  const response = await axiosInstance.get('api/v1/post');
  return response.data;
};

export const addNewPost = async (data) => {
  const response = await axiosInstance.post('api/v1/post', data, {
    headers: {
      Accept: 'multipart/form-data',
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const uploadProfilePic = async (values) => {
  console.log(values);
  const response = await axiosInstance.post('api/v1/user', values, {
    headers: {
      Accept: 'multipart/form-data',
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const addNewComment = async (data) => {
  const response = await axiosInstance.post(`api/v1/post/comment`, data);
  return response.data;
};

export const getAllComments = async (postId) => {
  const response = await axiosInstance.get(`api/v1/post/comment/all/${postId}`);
  return response.data;
};

export const getSuggestedUsers = async () => {
  const response = await axiosInstance.get(`api/v1/user/suggested`);
  return response.data;
};

export const followUser = async (followingId) => {
  const response = await axiosInstance.post(
    `api/v1/user/follow/${followingId}`
  );
  return response.data;
};

export const unFollowUser = async (followingId) => {
  const response = await axiosInstance.post(
    `api/v1/user/unfollow/${followingId}`
  );
  return response.data;
};

export const getUser = async (userId) => {
  const response = await axiosInstance.get(`api/v1/user/${userId}`);
  return response.data;
};

export const getFollowingPosts = async () => {
  const response = await axiosInstance.get(`api/v1/post/following`);
  return response.data;
};

export const getUserFollowers = async () => {
  const response = await axiosInstance.get(`api/v1/user/followers/following`);
  return response.data;
};

export const updateUser = async (data) => {
  const response = await axiosInstance.post(`api/v1/user`, data);
  return response.data;
};

export const createOrFindChannel = async (recieverId) => {
  const response = await axiosInstance.post(`api/v1/chat`, {
    reciever: recieverId,
  });
  return response.data;
};

export const getAllChannels = async () => {
  const response = await axiosInstance.get(`api/v1/chat/list`);
  return response.data;
};

export const addNotifications = async (subscription) => {
  const response = await axiosInstance.post(
    'api/v1/subscriptions/notifications',
    subscription
  );

  return response.data;
};

export const removeNotifications = async () => {
  const response = await axiosInstance.delete(
    'api/v1/subscriptions/notifications'
  );

  return response.data;
};

export const searchCreator = async (user) => {
  const response = await axiosInstance.get(`api/v1/user/search/${user}`);

  return response.data;
};

export const getPostLikes = async (postId) => {
  const response = await axiosInstance.get(`api/v1/post/like/${postId}`);

  return response.data;
};

export const likePost = async (data) => {
  return await axiosInstance.post(`api/v1/post/like`, data);
};

export const unlikePost = async (postId, id) => {
  return await axiosInstance.delete(`api/v1/post/like/${postId}/${id}`);
};

export const deletePosts = async (postId) => {
  return await axiosInstance.delete(`api/v1/post/${postId}`);
};

export const subscribeToCreator = async (creatorId) => {
  const response = await axiosInstance.post(`api/v1/subscription`, {
    creatorId,
  });

  return response.data;
};

export const getAllUsersPosts = async (userType) => {
  const response = await axiosInstance.get(`api/v1/post/all/${userType}`);

  return response.data;
};

export const getSubscriptions = async (userType) => {
  const response = await axiosInstance.get(`api/v1/subscription/${userType}`);

  return response.data;
};
