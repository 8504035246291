import React from 'react';
import { Avatar } from 'antd';
import { Comment } from '@ant-design/compatible';
import { constants } from '../../../../utils/constants';
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';
import { user } from '../../../../store/user';

import './ChatRow.scss';

export const ChatRow = ({ message }) => {
  const userData = useRecoilValue(user);

  return (
    <div
      className={`chat-row ${
        userData.id === message.sender.id ? 'chat-row-me' : undefined
      }`}
    >
      <Avatar
        src={`${constants.mediaURL}${message.sender.picture}`}
        alt={`${message.sender.firstname} ${message.sender.lastname}`}
      />

      <div className='chat-text'>{message.content}</div>
    </div>
  );
};
