import React, { useEffect, useState } from 'react';
import {
  UserOutlined,
  PhoneOutlined,
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';
import { Button, Input, Modal } from 'antd';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { user } from '../../../../../../store/user';
import { updateUser } from '../../../../../../api/api';
import { useIsMobile } from '../../../../../../utils/hooks';
import { useNavigate } from 'react-router-dom';
import { appHeader } from '../../../../../../store/header';

export const UpdateProfile = () => {
  const [buttonLoading, setbuttonLoading] = useState(false);
  const userData = useRecoilValue(user);
  const updateUserData = useSetRecoilState(user);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const setHeader = useSetRecoilState(appHeader);

  useEffect(() => {
    setHeader({ name: 'Update profile' });
  }, []);

  const formik = useFormik({
    initialValues: {
      firstname: userData.firstname,
      lastname: userData.lastname,
      phone: userData.phone,
      bio: userData.bio,
      facebook: userData.facebook,
      instagram: userData.instagram,
      twitter: userData.twitter,
      location: userData.location,
    },

    validationSchema: Yup.object().shape({
      firstname: Yup.string().required('First name is required'),
      lastname: Yup.string().required('Last name is required'),
      phone: Yup.string().required('Phone number is required'),
      bio: Yup.string(),
      facebook: Yup.string().url(),
      instagram: Yup.string().url(),
      twitter: Yup.string().url(),
      location: Yup.string(),
    }),

    onSubmit: async (values) => {
      setbuttonLoading(true);
      // Handle form submission
      const updatedUser = await updateUser(values);

      if (updatedUser?.results) {
        localStorage.setItem(
          'user',
          JSON.stringify({ token: userData.token, ...updatedUser?.results })
        );
        updateUserData({ token: userData.token, ...updatedUser?.results });
      }

      isMobile ? navigate('/profile') : setbuttonLoading(false);
    },
  });

  return (
    <div
      style={{
        marginTop: isMobile ? '20px' : '50px',
        padding: isMobile ? '0 20px' : 0,
      }}
    >
      {isMobile && (
        <Button
          loading={buttonLoading}
          type='primary'
          onClick={() => formik.handleSubmit()}
          disabled={!formik.isValid}
          style={{ width: '100%', marginBottom: '28px' }}
        >
          Save
        </Button>
      )}

      <div style={{ marginBottom: '28px' }}>
        <Input
          size='large'
          placeholder='First name'
          prefix={<UserOutlined style={{ color: '#A558C8' }} />}
          id='firstname'
          name='firstname'
          value={formik.values.firstname}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        {formik.touched.firstname && formik.errors.firstname && (
          <div style={{ fontSize: '15px', color: 'red' }}>
            {formik.errors.firstname}
          </div>
        )}
      </div>
      <div style={{ marginBottom: '28px' }}>
        <Input
          size='large'
          placeholder='Last name'
          prefix={<UserOutlined style={{ color: '#A558C8' }} />}
          id='lastname'
          name='lastname'
          value={formik.values.lastname}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        {formik.touched.lastname && formik.errors.lastname && (
          <div style={{ fontSize: '15px', color: 'red' }}>
            {formik.errors.lastname}
          </div>
        )}
      </div>
      <div style={{ marginBottom: '28px' }}>
        <Input
          size='large'
          placeholder='Phone number'
          prefix={<PhoneOutlined style={{ color: '#A558C8' }} />}
          id='phone'
          name='phone'
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        {formik.touched.phone && formik.errors.phone && (
          <div style={{ fontSize: '15px', color: 'red' }}>
            {formik.errors.phone}
          </div>
        )}
      </div>

      <div style={{ marginBottom: '28px' }}>
        <Input
          size='large'
          placeholder='Facebook URL'
          prefix={<FacebookOutlined style={{ color: '#A558C8' }} />}
          id='facebook'
          name='facebook'
          value={formik.values.facebook}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        {formik.touched.facebook && formik.errors.facebook && (
          <div style={{ fontSize: '15px', color: 'red' }}>
            {formik.errors.facebook}
          </div>
        )}
      </div>

      <div style={{ marginBottom: '28px' }}>
        <Input
          size='large'
          placeholder='Instagram URL'
          prefix={<InstagramOutlined style={{ color: '#A558C8' }} />}
          id='instagram'
          name='instagram'
          value={formik.values.instagram}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        {formik.touched.instagram && formik.errors.instagram && (
          <div style={{ fontSize: '15px', color: 'red' }}>
            {formik.errors.instagram}
          </div>
        )}
      </div>

      <div style={{ marginBottom: '28px' }}>
        <Input
          size='large'
          placeholder='Location'
          prefix={<EnvironmentOutlined style={{ color: '#A558C8' }} />}
          id='location'
          name='location'
          value={formik.values.location}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        {formik.touched.location && formik.errors.location && (
          <div style={{ fontSize: '15px', color: 'red' }}>
            {formik.errors.location}
          </div>
        )}
      </div>

      <div style={{ marginBottom: '28px' }}>
        <Input
          size='large'
          placeholder='Twitter URL'
          prefix={<TwitterOutlined style={{ color: '#A558C8' }} />}
          id='twitter'
          name='twitter'
          value={formik.values.twitter}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        {formik.touched.twitter && formik.errors.twitter && (
          <div style={{ fontSize: '15px', color: 'red' }}>
            {formik.errors.twitter}
          </div>
        )}
      </div>

      <div style={{ marginBottom: '28px' }}>
        <Input.TextArea
          rows={5}
          placeholder='Bio'
          id='bio'
          name='bio'
          value={formik.values.bio}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        {formik.touched.bio && formik.errors.bio && (
          <div style={{ fontSize: '15px', color: 'red' }}>
            {formik.errors.bio}
          </div>
        )}
      </div>

      {!isMobile && (
        <Button
          loading={buttonLoading}
          type='primary'
          onClick={() => formik.handleSubmit()}
          disabled={!formik.isValid}
        >
          Save
        </Button>
      )}
    </div>
  );
};
