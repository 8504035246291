import React, { useEffect } from 'react';
import { Empty } from 'antd';
import { useSetRecoilState } from 'recoil';
import { appHeader } from '../../store/header';

export const Notifications = () => {
  const setHeader = useSetRecoilState(appHeader);
  // registerNotifications(userData.id);

  useEffect(() => {
    setHeader({ name: 'Notifications', notifications: true });
  });

  return (
    <div style={{ marginTop: '10px' }}>
      <Empty description='No notifications yet' />
    </div>
  );
};
