import { BottomSheet } from 'react-spring-bottom-sheet';

// if setting up the CSS is tricky, you can add this to your page somewhere:
// <link rel="stylesheet" href="https://unpkg.com/react-spring-bottom-sheet/dist/style.css" crossorigin="anonymous">

import './BottomSheetComment.scss';

import { Form, Input, Spin, Empty } from 'antd';
import { Comment } from '@ant-design/compatible';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { SendOutlined } from '@ant-design/icons';

import './Comment.scss';
import { addNewComment, getAllComments } from '../../api/api';
import { constants } from '../../utils/constants';

const { TextArea } = Input;

const CommentList = ({ comments }) => {
  return (
    <div className='comment-list' style={{ padding: '0 20px' }}>
      {comments.map((comment) => (
        <Comment {...comment} />
      ))}
    </div>
  );
};

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item style={{ margin: 0 }}>
      <TextArea
        style={{ paddingRight: '50px' }}
        rows={2}
        onChange={onChange}
        value={value}
      />
    </Form.Item>
    <Form.Item className='custom-send'>
      <SendOutlined onClick={onSubmit} style={{ fontSize: '20px' }} />
    </Form.Item>
  </>
);

export const BottomSheetComment = ({
  isOpen,
  closeSheet,
  post,
  updateComment,
}) => {
  const [comments, setComments] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isOpen) {
      getComments();
    }
  }, [isOpen]);

  const getComments = async () => {
    const allComments = await getAllComments(post.id);

    if (allComments?.results) {
      const newComments = allComments?.results.map((comment) => {
        return {
          author: `${comment.user.firstname} ${comment.user.lastname}`,
          avatar: `${constants.mediaURL}${comment?.user.picture}`,
          content: <p>{comment.comment}</p>,
          datetime: dayjs(comment.createdAt).fromNow(),
        };
      });

      setComments(newComments);
    }

    setLoading(false);
  };

  const handleSubmit = async () => {
    if (!value) return;
    setSubmitting(true);

    const addComment = await addNewComment({
      comment: value,
      parentId: null,
      postId: post.id,
    });

    if (addComment?.results) {
      const comment = addComment?.results;
      updateComment(comment);
      setComments([
        {
          author: `${comment.user.firstname} ${comment.user.lastname}`,
          avatar: `${constants.mediaURL}${comment?.user.picture}`,
          content: <p>{comment.comment}</p>,
          datetime: dayjs(comment.createdAt).fromNow(),
        },
        ...comments,
      ]);
    }

    setSubmitting(false);
    setValue('');
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <>
      <BottomSheet
        className='bottomsheeet'
        open={isOpen}
        onDismiss={() => closeSheet()}
        defaultSnap={({ snapPoints, lastSnap }) =>
          lastSnap ?? Math.min(...snapPoints)
        }
        snapPoints={({ maxHeight }) => [
          maxHeight - maxHeight / 5,
          maxHeight * 0.6,
        ]}
        header={<h4>Comments</h4>}
        footer={
          <Comment
            className='custom-comment'
            content={
              <Editor
                onChange={handleChange}
                onSubmit={handleSubmit}
                submitting={submitting}
                value={value}
              />
            }
          />
        }
      >
        <>
          {loading ? (
            <Spin size='large' />
          ) : comments.length > 0 ? (
            <CommentList comments={comments} />
          ) : (
            <Empty description='No comments, be the first' />
          )}
        </>
      </BottomSheet>
    </>
  );
};
