import React, { useState } from 'react';
import '../Authentication.scss';
import logo from '../../../assets/images/logo.svg';
import { UserOutlined } from '@ant-design/icons';
import { Button, Input, Typography, Divider, notification } from 'antd';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FacebookAuth } from '../SocialLogin/FacebookAuth';
import { GoogleAuth } from '../SocialLogin/GoogleAuth';
import { forgotpassword } from '../../../api/api';
const { Title, Text } = Typography;

export const ForgotPassword = () => {
  const [buttonLoading, setbuttonLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    }),
    onSubmit: async (values) => {
      setbuttonLoading(true);
      // Handle form submission
      const forgotPasswordResponse = await forgotpassword(values);

      if (forgotPasswordResponse.results) {
        notification.success({
          message:
            'If the email provided exists in our system, you will receive an email shortly.',
        });
      }
      setbuttonLoading(false);
    },
  });

  return (
    <div className='auth-wrapper'>
      <div className='left-container'>
        <img className='logo' src={logo} alt='Logo' />
      </div>
      <div className='right-container'>
        <img className='logo' src={logo} alt='Logo' />
        <div style={{ marginBottom: '32px', textAlign: 'center' }}>
          <Title level={2} style={{ marginBottom: '5px' }}>
            Welcome back
          </Title>
          <Text>Input your Email Address </Text>
        </div>

        <div style={{ marginBottom: '28px' }}>
          <Input
            size='large'
            placeholder='Email'
            prefix={<UserOutlined style={{ color: '#A558C8' }} />}
            id='email'
            name='email'
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          {formik.touched.email && formik.errors.email && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.email}
            </div>
          )}
        </div>

        <Button
          loading={buttonLoading}
          type='primary'
          onClick={() => formik.handleSubmit()}
          disabled={!formik.isValid}
        >
          Submit
        </Button>
        <Divider>or login with</Divider>

        <FacebookAuth />
        <GoogleAuth />

        <div style={{ color: '#ACACAC', textAlign: 'center' }}>
          Don't have an account?{' '}
          <Link
            to='/register'
            style={{ textDecoration: 'none', color: '#5D5D5D' }}
          >
            Sign Up
          </Link>
        </div>
      </div>
    </div>
  );
};
