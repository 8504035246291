import React, { useState } from 'react';
import '../Authentication.scss';
import logo from '../../../assets/images/logo.svg';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Typography, Divider } from 'antd';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSetRecoilState } from 'recoil';
import { isUserLoggedIn, user } from '../../../store/user';
import { FacebookAuth } from '../SocialLogin/FacebookAuth';
import { GoogleAuth } from '../SocialLogin/GoogleAuth';
import { login } from '../../../api/api';
const { Title, Text } = Typography;

export const LogIn = () => {
  const [buttonLoading, setbuttonLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const setUser = useSetRecoilState(user);
  const setIsLoggedIn = useSetRecoilState(isUserLoggedIn);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: async (values) => {
      setbuttonLoading(true);
      // Handle form submission
      const loginResponse = await login(values);

      if (loginResponse?.results) {
        localStorage.setItem('user', JSON.stringify(loginResponse.results));
        setUser(loginResponse.results);
        setIsLoggedIn(true);
      }

      setbuttonLoading(false);
    },
  });

  return (
    <div className='auth-wrapper'>
      <div className='left-container'>
        <img className='logo' src={logo} alt='Logo' />
      </div>
      <div className='right-container'>
        <img className='logo' src={logo} alt='Logo' />
        <div style={{ marginBottom: '32px', textAlign: 'center' }}>
          <Title level={2} style={{ marginBottom: '5px' }}>
            Welcome back
          </Title>
          <Text>Input your Email Address & Password</Text>
        </div>

        <div style={{ marginBottom: '28px' }}>
          <Input
            size='large'
            placeholder='Email'
            prefix={<UserOutlined style={{ color: '#A558C8' }} />}
            id='email'
            name='email'
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          {formik.touched.email && formik.errors.email && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.email}
            </div>
          )}
        </div>
        <div style={{ marginBottom: '28px' }}>
          <Input.Password
            size='large'
            type='password'
            placeholder='Password'
            prefix={<LockOutlined style={{ color: '#A558C8' }} />}
            id='password'
            name='password'
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            visibilityToggle={{
              visible: passwordVisible,
              onVisibleChange: setPasswordVisible,
            }}
          />

          {formik.touched.password && formik.errors.password && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.password}
            </div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '22px',
            alignItems: 'center',
          }}
        >
          <Checkbox onChange={() => {}} style={{ fontWeight: '500' }}>
            Remember me
          </Checkbox>
          <Link to='/forgot-password' style={{ textDecoration: 'none' }}>
            Forgot your password?
          </Link>
        </div>

        <Button
          loading={buttonLoading}
          type='primary'
          onClick={() => formik.handleSubmit()}
          disabled={!formik.isValid}
        >
          Sign In
        </Button>
        <Divider>or login with</Divider>

        <FacebookAuth />
        <GoogleAuth />

        <div style={{ color: '#ACACAC', textAlign: 'center' }}>
          Don't have an account?{' '}
          <Link
            to='/register'
            style={{ textDecoration: 'none', color: '#5D5D5D' }}
          >
            Sign Up
          </Link>
        </div>
      </div>
    </div>
  );
};
