import React from 'react';
import logo from '../../assets/images/logo.svg';

import './SplashScreen.scss';

export const SplashScreen = () => {
  return (
    <div className='splash-screen'>
      <img src={logo} alt='BONVUE' />
    </div>
  );
};
