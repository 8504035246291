import React, { useState, useEffect } from 'react';
import { Avatar, Empty, List, Spin } from 'antd';

import { constants } from '../../utils/constants';
import { getUserFollowers } from '../../api/api';
import { useSetRecoilState } from 'recoil';
import { appHeader } from '../../store/header';

export const Followers = () => {
  const [followers, setFollowers] = useState([]);
  const [loading, setLoading] = useState(true);
  const setHeader = useSetRecoilState(appHeader);

  useEffect(() => {
    setHeader({ name: 'Fans' });
    getUserFollowingFollowers();
  }, []);

  const getUserFollowingFollowers = async () => {
    const followers = await getUserFollowers();

    if (followers?.results) {
      setFollowers(followers?.results.followers);
    }

    setLoading(false);
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Spin size='large' />
      </div>
    );
  }

  if (!followers.length) {
    return <Empty description={<span>No fans yet</span>} />;
  }

  return (
    <List
      itemLayout='horizontal'
      dataSource={followers}
      renderItem={(item, index) => (
        <List.Item style={{ cursor: 'pointer' }}>
          <List.Item.Meta
            style={{
              alignItems: 'center',
            }}
            avatar={
              <Avatar src={`${constants.mediaURL}${item.follower.picture}`} />
            }
            title={
              <span>{`${item.follower.firstname} ${item.follower.lastname}`}</span>
            }
          />
        </List.Item>
      )}
    />
  );
};
