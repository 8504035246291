import axios from 'axios';
import { notification } from 'antd';

const axiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8080/'
      : 'https://bonvue-backend-fceik.ondigitalocean.app/',
  // Additional Axios configuration options can be added here
});

// Add an interceptor to set the authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    const user = localStorage.getItem('user');
    if (user) {
      const parsedUser = JSON.parse(user);
      config.headers['Authorization'] = `Bearer ${parsedUser.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle error here
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem('user');
        notification.error({
          message: 'Your sesion has expired',
        });

        setTimeout(() => {
          window.location = '/';
        }, 1000);
      } else {
        // The request was made and the server responded with a status code
        notification.error({
          message: error.response.data.message[0],
        });
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.log('Error request:', error);
    } else {
      // Something happened in setting up the request that triggered an error
      console.log('Error message:');
    }
    return Promise.resolve(error);
  }
);

export default axiosInstance;
