import React, { useState } from 'react';
import { Radio } from 'antd';
import '../Authentication.scss';
import logo from '../../../assets/images/logo.svg';
import { LockOutlined, UserOutlined, PhoneOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Typography, Divider } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSetRecoilState } from 'recoil';
import { isUserLoggedIn, user } from '../../../store/user';
import { FacebookAuth } from '../SocialLogin/FacebookAuth';
import { GoogleAuth } from '../SocialLogin/GoogleAuth';
import { register } from '../../../api/api';
const { Title, Text } = Typography;

export const Register = () => {
  const [buttonLoading, setbuttonLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const setUser = useSetRecoilState(user);
  const isLoggedIn = useSetRecoilState(isUserLoggedIn);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      firstname: '',
      lastname: '',
      phone: '',
      usertype: 'USER',
      repeatPassword: '',
    },

    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      password: Yup.string().required('Password is required'),
      repeatPassword: Yup.string()
        .required('Repeat password a is required field.')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
      firstname: Yup.string().required('First name is required'),
      lastname: Yup.string().required('Last name is required'),
      phone: Yup.string().required('Phone number is required'),
    }),

    onSubmit: async (values) => {
      delete values.repeatPassword;
      setbuttonLoading(true);
      // Handle form submission
      const registerResponse = await register(values);

      if (registerResponse?.results) {
        localStorage.setItem('user', JSON.stringify(registerResponse.results));
        setUser(registerResponse.results);
        isLoggedIn(true);
        navigate('/');
      }
      setbuttonLoading(false);
    },
  });

  const checkTerms = (event) => {
    setTermsAgreed(event.target.checked);
  };

  return (
    <div className='auth-wrapper'>
      <div className='left-container'>
        <img className='logo' src={logo} alt='Logo' />
      </div>
      <div className='right-container'>
        <img className='logo' src={logo} alt='Logo' />
        <div style={{ marginBottom: '32px', textAlign: 'center' }}>
          <Title level={2} style={{ marginBottom: '5px' }}>
            Welcome
          </Title>
          <Text>
            Please fill out the following in order to create an account
          </Text>
        </div>

        <div style={{ marginBottom: '28px' }}>
          <Input
            size='large'
            placeholder='Email'
            prefix={<UserOutlined style={{ color: '#A558C8' }} />}
            id='email'
            name='email'
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          {formik.touched.email && formik.errors.email && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.email}
            </div>
          )}
        </div>
        <div style={{ marginBottom: '28px' }}>
          <Input.Password
            size='large'
            type='password'
            placeholder='Password'
            prefix={<LockOutlined style={{ color: '#A558C8' }} />}
            id='password'
            name='password'
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            visibilityToggle={{
              visible: passwordVisible,
              onVisibleChange: setPasswordVisible,
            }}
          />

          {formik.touched.password && formik.errors.password && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.password}
            </div>
          )}
        </div>

        <div style={{ marginBottom: '28px' }}>
          <Input.Password
            size='large'
            type='password'
            placeholder='Repeat password'
            prefix={<LockOutlined style={{ color: '#A558C8' }} />}
            id='repeatPassword'
            name='repeatPassword'
            value={formik.values.repeatPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            visibilityToggle={{
              visible: passwordVisible,
              onVisibleChange: setPasswordVisible,
            }}
          />

          {formik.touched.repeatPassword && formik.errors.repeatPassword && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.repeatPassword}
            </div>
          )}
        </div>

        <div style={{ marginBottom: '28px', textAlign: 'center' }}>
          <Radio.Group
            value={formik.values.usertype}
            name='usertype'
            id='usertype'
            onChange={formik.handleChange}
          >
            <Radio value='USER'>Fan</Radio>
            <Radio value='CREATOR'>Creator</Radio>
          </Radio.Group>

          {formik.touched.password && formik.errors.password && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.password}
            </div>
          )}
        </div>

        <div style={{ marginBottom: '28px' }}>
          <Input
            size='large'
            placeholder='First name'
            prefix={<UserOutlined style={{ color: '#A558C8' }} />}
            id='firstname'
            name='firstname'
            value={formik.values.firstname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          {formik.touched.firstname && formik.errors.firstname && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.firstname}
            </div>
          )}
        </div>
        <div style={{ marginBottom: '28px' }}>
          <Input
            size='large'
            placeholder='Last name'
            prefix={<UserOutlined style={{ color: '#A558C8' }} />}
            id='lastname'
            name='lastname'
            value={formik.values.lastname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          {formik.touched.lastname && formik.errors.lastname && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.lastname}
            </div>
          )}
        </div>
        <div style={{ marginBottom: '28px' }}>
          <Input
            size='large'
            placeholder='Phone number'
            prefix={<PhoneOutlined style={{ color: '#A558C8' }} />}
            id='phone'
            name='phone'
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          {formik.touched.phone && formik.errors.phone && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.phone}
            </div>
          )}
        </div>
        <div
          style={{
            marginBottom: '22px',
          }}
        >
          <Checkbox onChange={checkTerms} style={{ color: '#ACACAC' }}>
            Agree to{' '}
            <span style={{ color: '#5D5D5D' }}>Terms and Conditions</span>
          </Checkbox>
        </div>

        <Button
          loading={buttonLoading}
          type='primary'
          onClick={() => formik.handleSubmit()}
          disabled={!termsAgreed || !formik.isValid}
        >
          Sign Up
        </Button>
        <Divider>or login with</Divider>

        <FacebookAuth />
        <GoogleAuth />

        <div style={{ color: '#ACACAC', textAlign: 'center' }}>
          Already have an account?{' '}
          <Link to='/' style={{ textDecoration: 'none', color: '#5D5D5D' }}>
            Sign In
          </Link>
        </div>
      </div>
    </div>
  );
};
