import React from 'react';
import './Suggestion.scss';
import { constants } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';

const Suggestion = ({ profile }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() =>
        navigate(`/public-profile/${profile.id}`, {
          state: {
            name: `${profile.firstname} ${profile.lastname}`,
          },
        })
      }
      className='suggestion-item-wrapper'
      style={{ backgroundImage: `url(${constants.mediaURL}${profile.cover})` }}
    >
      <img
        className='avatar'
        src={`${constants.mediaURL}${profile.picture}`}
        alt='Logo'
      />
    </div>
  );
};

export default Suggestion;
