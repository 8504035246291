import React from 'react';
import { useRecoilState } from 'recoil';

import { themesSwitchState } from './../../store/themeSwitch';
import { ReactComponent as Sun } from '../../assets/svg/sun.svg';
import { ReactComponent as Moon } from '../../assets/svg/moon.svg';

import './ThemeSwitch.scss';

const ThemeSwitch = () => {
  const [theme, setTheme] = useRecoilState(themesSwitchState);

  const toggleDarkMode = (theme) => {
    setTheme(theme);
  };

  return (
    // <Switch
    //   style={{ backgroundColor: theme === 'dark' ? '#A558C8' : 'gray' }}
    //   checked={theme === 'dark'}
    //   onChange={toggleDarkMode}
    //   checkedChildren={<Sun />}
    //   unCheckedChildren='Light'
    // />

    <div className='theme-switch'>
      {theme === 'dark' ? (
        <Sun onClick={() => toggleDarkMode('light')} />
      ) : (
        <Moon onClick={() => toggleDarkMode('dark')} />
      )}
    </div>
  );
};

export default ThemeSwitch;
