import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './BottomPanel.scss';
import { Menu } from 'antd';
import {
  HomeOutlined,
  MessageOutlined,
  HeartFilled,
  PlusCircleFilled,
  MoreOutlined,
} from '@ant-design/icons';

import Dropdown from 'antd/lib/dropdown/dropdown';
import { useRecoilValue } from 'recoil';
import { user } from '../../store/user';
import { userType } from '../../utils/constants';

const BottomPanel = () => {
  const location = useLocation();
  const userData = useRecoilValue(user);
  const navigate = useNavigate();

  function isPWAInstalledOnIOS() {
    return 'standalone' in navigator && navigator.standalone;
  }

  const [isPWA, setIsPWA] = useState(isPWAInstalledOnIOS());

  const items = [
    {
      label: 'New post',
      key: '1',
      onClick: (e) => navigate('/new-post'),
    },
    {
      label: 'New story',
      key: '2',
      disabled: true,
    },
  ];

  const routes =
    userData.usertype === userType.user
      ? [
          {
            key: '/feed',
            icon: <HomeOutlined />,
            label: <Link to='/feed' />,
          },
          {
            key: '/messages',
            icon: <MessageOutlined />,
            label: <Link to='/messages' />,
          },
          {
            key: '/creators',
            icon: <HeartFilled />,
            label: <Link to='/creators' className='special-link'></Link>,
          },
          {
            key: '/more',
            icon: <MoreOutlined />,
            label: <Link to='/more' className='special-link'></Link>,
          },
        ]
      : [
          {
            key: '/feed',
            icon: <HomeOutlined />,
            label: <Link to='feed' />,
          },
          {
            key: '3',
            icon: <MessageOutlined />,
            label: <Link to='messages' />,
          },
          {
            key: '7',
            icon: (
              <Dropdown
                className='create-drop'
                placement='top'
                menu={{
                  items,
                }}
              >
                <PlusCircleFilled />
              </Dropdown>
            ),
            label: '',
          },
          {
            key: '/fans',
            icon: <HeartFilled />,
            label: <Link to='fans' className='special-link'></Link>,
          },
          {
            key: '/more',
            icon: <MoreOutlined />,
            label: <Link to='more' className='special-link'></Link>,
          },
        ];

  return (
    <div className={`bottom-panel-wrapper ${isPWA ? 'safari-only' : ''}`}>
      <Menu
        theme='light'
        mode='horizontal'
        defaultSelectedKeys={[location.pathname]}
        items={routes}
      />
    </div>
  );
};

export default BottomPanel;
