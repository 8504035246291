import React from 'react';
import { Layout } from 'antd';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';
import MiddlePanel from './MiddlePanel';
import BottomPanel from './BottomPanel';
import './Home.scss';
import { Breakpoint } from 'react-socks';

export const Home = () => {
  return (
    <div className='home-wrapper'>
      <Layout>
        <Breakpoint customQuery='(min-width: 768px)' className='left-panel'>
          <LeftPanel />
        </Breakpoint>
        <MiddlePanel />
        <Breakpoint customQuery='(min-width: 996px)' className='right-panel'>
          <React.Suspense fallback={<div>Loading...</div>}>
            <RightPanel />
          </React.Suspense>
        </Breakpoint>
      </Layout>
      <Breakpoint
        customQuery='(max-width: 768px)'
        className='breakpoint-position'
      >
        <BottomPanel />
      </Breakpoint>
    </div>
  );
};
