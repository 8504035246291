import React, { useEffect } from 'react';
import { Tabs } from 'antd';

import { useSetRecoilState } from 'recoil';
import { Subscriptions } from '../Subscribtions/Subscribtions';
import { Following } from '../Following/Following';
import { appHeader } from '../../store/header';

export const Creators = () => {
  const setHeader = useSetRecoilState(appHeader);

  useEffect(() => {
    setHeader({ name: 'Creators' });
  }, []);

  const items = [
    {
      key: '1',
      label: `Subscriptions`,
      children: <Subscriptions />,
    },
    {
      key: '2',
      label: `Following`,
      children: <Following />,
    },
  ];

  return (
    <Tabs
      rootClassName='feed-content'
      tabBarStyle={{ background: 'white', padding: '0 20px' }}
      className='feed-tabs'
      defaultActiveKey='1'
      items={items}
    />
  );
};
